(function initModule() {
    const popovers = [ ...document.querySelectorAll( '.has-popover' ) ];

    popovers.forEach( popover => {
        const body = document.querySelector( 'body' );
        const parentElement = popover.parentNode;
        const target = popover.dataset.target;
        const targetElement = document.querySelector( `#${target}` );
        let popoverIsOpen = false;

        popover.addEventListener( 'click', ( event ) => {

            if ( popoverIsOpen ) {
                parentElement.classList.remove( 'active' );
                targetElement.classList.add( 'is-hidden' );
                popoverIsOpen = false;
            } else {
                parentElement.classList.add( 'active' );
                targetElement.classList.remove( 'is-hidden' );
                popoverIsOpen = true;
            }

            event.stopPropagation();
        } );

        body.addEventListener( 'click', () => {
            if ( popoverIsOpen ) {
                parentElement.classList.remove( 'active' );
                targetElement.classList.add( 'is-hidden' );
                popoverIsOpen = false;
            }
        } );
    } );
})();
